<template>
  <div
    ref="formEl"
    class="landing-search-form"
  >
    <ZCard
      no-body
      class="inputs-card"
    >
      <div class="filter-tabs">
        <div
         
          :class="['tab-option', { 'tab-option-selected': tabsFilter === tabFilters.SearchAll }]"
          @click="selectTab(tabFilters.SearchAll)"
        >
          <Fa :icon="['fal', 'magnifying-glass']" />
          <span class="d-md-none d-lg-none d-xl-none">{{ t('all') }}</span>
          <span class="d-none d-md-inline">{{ t('searchAll') }}</span>
        </div>
        <div
         
          :class="['tab-option', { 'tab-option-selected': tabsFilter === tabFilters.Drivable }]"
          @click="selectTab(tabFilters.Drivable)"
        >
          <Fa :icon="['fal', 'rv']" /> {{ t('drivable') }}
        </div>
        <div
         
          :class="['tab-option', { 'tab-option-selected': tabsFilter === tabFilters.Towable }]"
          @click="selectTab(tabFilters.Towable)"
        >
          <Fa :icon="['fal', 'caravan']" /> {{ t('towable') }}
        </div>
        <div
         
          :class="['tab-option', { 'tab-option-selected': tabsFilter === tabFilters.Delivery }]"
          @click="selectTab(tabFilters.Delivery)"
        >
          <Fa :icon="['fal', 'truck-fast']" /> {{ t('delivery') }}
        </div>
      </div>

      <SearchForm
        ref="searchFormEl"
       
        class="inputs"
        :search-type="(tabsFilter as string)"
        show-search-button
      />
    </ZCard>
  </div>
</template>

<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core'
import type { ComponentInstance } from 'vue'
import { SearchForm } from '#components'

import { SearchTypes } from '~/lib/enums'

const { t } = useI18n()

const searchFormEl = ref<ComponentInstance<typeof SearchForm>>()
function animateChangeTab() {
  searchFormEl.value?.$el.animate(
    [{ opacity: '0.25' }, { opacity: '0.4' }, { opacity: '0.5' }, { opacity: '0.75' }, { opacity: '1' }],
    {
      duration: 500,
    },
  )
}
const tabFilters = computed(() => SearchTypes)
const tabsFilter = ref<SearchTypes>(SearchTypes.SearchAll)
function selectTab(tab: SearchTypes) {
  animateChangeTab()
  tabsFilter.value = tab
}

const showHeaderSearchForm = useShowHeaderSearchForm()
const formEl = ref<HTMLDivElement>()
const isVisible = useElementVisibility(formEl)

watch(isVisible, () => {
  showHeaderSearchForm.value = !isVisible.value
})

// TODO see if we still need this?
onBeforeUnmount(() => (showHeaderSearchForm.value = true))
</script>

<style scoped lang="scss">
.landing-search-form {
  .inputs-card {
    border-radius: 1.5rem 1.5rem 1rem 1rem;
    margin: auto;

    @include media-max-size(medium) {
      max-width: 22rem;
    }

    @include media-min-size(medium) {
      max-width: 940px;
      width: 100%;
    }

    .filter-tabs {
      display: flex;
      box-shadow: 0 0.25rem 0.5rem rgba(3, 46, 90, 0.15);
      width: 100%;
      justify-content: center;
      border-radius: 1.5rem 1.5rem 0 0;
      padding: 0.25rem 0.5rem 0 0.5rem;

      .tab-option {
        cursor: pointer;
        padding: 0.75rem 1rem;
        margin: 0 0.5rem;
        color: getColor('primary-350');
        @include captionBase;

        @include media-max-size(medium) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        @include media-min-size(large) {
          margin: 0 1.125rem;
        }

        svg {
          margin-right: 0.5rem;

          @include media-max-size(medium) {
            width: auto;
            margin-right: 0;
          }
        }

        @include media-max-size(medium) {
          padding: 0.25rem 0.5rem;
          margin: 0 0.35rem;
        }

        &-selected {
          font-weight: 600;
          color: getColor('primary-500');
          border-bottom: 2px solid getColor('highlight-500');
        }
      }
    }

    .inputs {
      margin: 1rem;

      @include media-min-size(medium) {
        width: auto;
        margin: 1.5rem;
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "searchAll": "Search all RVs",
    "all": "All",
    "drivable": "Drivable",
    "towable": "Towable",
    "delivery": "Delivery"
  },
  "fr": {
    "searchAll": "Rechercher tous les VR",
    "all": "Tous",
    "drivable": "Conduire",
    "towable": "Remorquer",
    "delivery": "Livraison"
  }
}
</i18n>
